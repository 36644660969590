import React, { Component } from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";

import axios from "axios";

import { navigate } from "gatsby";

class CheckoutForm extends Component {
  state = {
    errorMessage: "",
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    amount: this.props.amount,
    description: this.props.description,
    buttonDisabled: this.props.acceptedTC,
    products:[{name:this.props.description, images:this.props.images, quantity:1, amount:this.props.amount}]
  };

  // disableButt = () => {
  //   this.setState({visibility: 'hidden'})
  // }

  static getDerivedStateFromProps(props,state){
    return {buttonDisabled:props.acceptedTC}
  }

  // handleChange = ({ elementType, complete, error }) => {
  //   let { buttonDisabled } = {...this.state}
  //   if (buttonDisabled == false) {  
  //     buttonDisabled = true
  //     this.setState({buttonDisabled})
  //   }
  //   if (error) {return this.setState({ errorMessage: error.message, buttonDisabled: false})}else{return this.setState({ errorMessage: '', buttonDisabled: false})};
  //   return this.setState({ [elementType]: complete });
  // };

  // handleInputChange = (e) => {
  //   let { buttonDisabled } = {...this.state}
  //   if (buttonDisabled == false) {  
  //     buttonDisabled = true
  //     this.setState({buttonDisabled})
  //   }
  //   return this.setState({ [e.target.name]: e.target.value });
  // }

  // handleSubmit = async e => {
  //   e.preventDefault();
  //   let { buttonDisabled } = {...this.state}
  //   buttonDisabled = true
  //   this.setState({buttonDisabled})
  //   const { name, email, phone, pc, amount, description } = this.state;
  //   if (this.props.stripe) {
  //     const { token } = await this.props.stripe.createToken({ name:name, email });
  //     //console.log('token ====>',token)
  //     const response = await axios.post(`/signup`, {
  //       token_id: token.id,
  //       amount,
  //       name,
  //       email,
  //       phone,
  //       pc,
  //       description
  //     });
  //     // console.log('response ====>',response.data)
  //     response.data.status == "succeeded" ? navigate('/thanks') : this.setState({buttonDisabled: false},()=>alert("Payment error"));
  //   } else {
  //     alert("Stripe.js hasn't loaded yet.");
  //   }
  // };

  render() {
//=====================================================================================
//=======================  CREATE CHECKOUT SESSION  ===================================
// =====================================================================================   
let dataLayer = [];
function gtag(){dataLayer.push(arguments);}
// gtag('js', new Date());

// gtag('config', 'UA-66109172-1');
// gtag('config','AW-856512071')
function gtag_report_conversion(url,money=5800.0) {

  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
    'send_to': 'AW-856512071/Y6DLCLmAxsoBEMestZgD',
    'value': money,
    'currency': 'EUR',
    'transaction_id': '',
    'event_callback': callback
  });
  return false;
}

  // all new code inside .post is not propagating by itself
  const createCheckoutSession = async() => {
    gtag_report_conversion(window.location.href)
    try{
      const response = await axios.post('https://barcelonacodeschool.com/payment/create-checkout-session',this.state.products)
      return response.data.ok 
      ? (
       localStorage.setItem('sessionId', JSON.stringify(response.data.sessionId)),
       redirect(response.data.sessionId)
       )
      : navigate('/payment/error')
    }catch(error){
      // console.log('E R R O R', error)
      navigate('/payment/error')
    }
  }
//=====================================================================================
//=======================  REDIRECT TO STRIPE CHECKOUT  ===============================
//=====================================================================================
const redirect = (sessionId) => {
  // console.log('THIS P R O P S ', this.props)
  this.props.stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId
        
      }).then(function (result) {
        alert(result.error.message)
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      });
    } 
//=====================================================================================
//=====================================================================================
//=====================================================================================
return (
  <div className='checkout_container'> 
  <div className='products_list'>
  </div>    

  <div className='footer'>
  {!this.state.buttonDisabled ? 
  <button disabled={this.state.buttonDisabled} onClick={()=>createCheckoutSession()} >Continue to checkout</button> : null }

</div>
</div>
);
}
}

export default injectStripe(CheckoutForm);
